<template>
  <view>
    Redirecting...
  </view>
</template>

<script>
import { DataService } from "../../services/DataService";

const dataService = new DataService();

export default {
  mounted() {
    dataService.getConfig("passiveDataConnectUrls").then(data => {
      if (data[this.$route.query.source]) {
        window.location.href = data[this.$route.query.source]
      } else {
        // TODO: refactor
        console.log(404)
      }
    })
  }
}
</script>